import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import downloadFile from "../assets/supreme-court-notes.pdf"
import { Link } from "gatsby"

const MapPage = () => (
  <Layout>
    <Seo title="The Current Supreme Court Super Majority: A Threat to Science and Secularism" />
    <div className="row page-row">
      <div className="col-12 col-lg-8">
        <div className="page-header">
          <hr />
          <h1>
            <strong>
              The Current Supreme Court Super Majority: A Threat to Science and
              Secularism Notes
            </strong>
          </h1>
        </div>

        <div className="entry-content conference-max mt-3">
          <p>
            You can download the notes for this talk here{" "}
            <a href={downloadFile} download>
              here
            </a>
          </p>
          <p>
            THE CURRENT SUPREME COURT SUPER MAJORITY: A THREAT TO SCIENCE AND
            SECULARISM. CSICON 2022
          </p>
          <p>
            <strong>
              Copyright © 2022 Center for Inquiry and Edward Tabash{" "}
            </strong>
          </p>
          <p>
            Most of the work that CFI does involving the Supreme Court has been
            in the realm of separation of church and state. CFI Counsel, Nick
            Little, and I, have filed numerous briefs with the Court detailing
            why the religious right wing is wrong when it asserts that
            government can favor belief over nonbelief and that government must
            allow religious objectors the right to avoid complying with laws
            that are binding on the rest of us.
          </p>
          <p>
            We are going to focus on how the now 6 to 3 religious right majority
            on the Court issues rulings that defy scientific evidence. We will
            see how the Court rejects science as part of the effort to undo
            church/state separation. We will also see how the Court dismisses
            scientific evidence, generally, in order to achieve other
            ideological objectives.
          </p>
          <p>
            We must now confront the awful tragedy that the one institution of
            government that we could most reliably trust to protect our personal
            freedoms and to make decisions based on reason, from the late 1940s
            onward, has now become a weapon for imposing religious tyranny and
            ignoring the very science it used to revere.
          </p>
          <p>
            THE COURT’S HISTORICAL PRECEDENTS IN DEFINING SCIENTIFICALLY BASED
            KNOWLEDGE.
          </p>
          <p>
            In the 1993 case of{" "}
            <em>Daubert v. Merrill Dow Pharmaceuticals, </em>the Court set forth
            criteria for determining if someone who claims to be an expert in a
            relevant scientific area should be permitted to testify. The Court
            ruled that any supposed scientific explanation, in order to be
            admissible, must be capable of empirical testing, quoting Karl
            Popper by name. The Court then set forth it’s definition of
            knowledge, writing that “the word ‘knowledge’ connotes more than
            subjective belief or unsupported speculation. The term applies to
            any body of known facts or to any body of ideas inferred from such
            facts or accepted as truths on good grounds.” An example was
            provided explaining how a study of the phases of the moon might be
            relevant as to whether a particular night was dark, if such darkness
            were an issue in the case. However, it would not be admissible to
            show when a full moon appeared in order to argue that such a moon
            can affect whether someone behaves rationally or not. In the 1999
            case of <em>Kumho Tire Company v. Carmichael</em>, the Court said
            that some categories of claimed knowledge are so inherently
            unreliable that supposed expert testimony based on them would never
            be admissible. The Court cited astrology and necromancy as examples.
          </p>

          <p>
            However, the Court’s determination of what constitutes valid
            scientific knowledge for purposes of admissibility does not spell
            out what weight must be given to such evidence. We can see this by
            the way the current Court majority has ignored clear scientific
            evidence, in order to obtain outcomes based on unconstitutional
            religious and political motives.
          </p>
          <p>
            WE ARE LUCKY THAT THE COURT HAD NOT YET FALLEN TO THE BARBARIANS THE
            LAST TIME IT DECIDED AN EVOLUTION AND CREATIONISM CASE.
          </p>
          <p>
            We were fortunate that the Court did not yet have a religious right
            wing majority when it decided <em>Edwards v. Aguillard </em>in 1987.
            Louisiana passed a law requiring that creationism be taught in the
            public schools alongside evolution in order to provide a so called
            “balanced view.” The state argued that it should be given the chance
            to demonstrate that this law was not based on religion but on a
            sound scientific theory about how the universe and everything in it,
            including humans, suddenly appeared in our present forms. We won 7
            to 2. However, the two dissenters, Justice Scalia, joined by then
            Chief Justice Rehnquist, argued that Louisiana should have a chance
            to demonstrate its claim, because there are numerous reputable
            scientists who agree with this spontaneous, sudden appearance of
            everything in the forms we have today, assuring us that they don’t
            need to base their assertions on any deity or supernatural being. It
            was frightening to see how two of the most well known justices in
            modern times dismissed science in order to clear the way for
            government to promote pure religious belief.
          </p>
          <p>
            It’s even more frightening that what was then a minority of two
            justices who would intentionally flout science in order to assist
            government in promoting religion has today sadly grown into a super
            majority of six.
          </p>
          <p>
            THE PRESENT SUPREME COURT MAJORITY DEMONSTRATED CONTEMPT FOR SCIENCE
            BY CARVING OUT SPECIAL RIGHTS PERMITTING ONLY RELIGIOUS OBJECTORS TO
            AVOID COMPLYING WITH OTHERWISE SOCIETY WIDE COVID SAFETY MEASURES.
          </p>
          <p>
            In late 2020, in{" "}
            <em>Roman Catholic Diocese of Brooklyn v. Cuomo</em>, the Court
            misused the concept of free exercise of religion to strike down
            restrictions on the number of people who can gather for worship
            services. In dissent, Justice Breyer pointed out that the rules
            established for religious activities also applied to similar
            meetings at public lectures, concerts, and theatrical performances
            where — as in places of worship — people were more likely to be
            gathered together more closely for longer periods of time. Further,
            Breyer argued that the Court’s precedents recognize the importance
            of courts’ granting
          </p>

          <p>
            elected officials broad discretion when they must act on the basis
            of uncertain and swiftly changing medical and scientific
            information, as in the situation New York was facing with Covid.
          </p>
          <p>
            Breyer lamented the majority’s decision because members of the
            scientific and medical communities tell us that the virus is
            transmitted from person to person through respiratory droplets
            produced when an individual or group of people talk, sing, cough, or
            breathe near each other, citing the briefs of the World Health
            Organization and of the American Medical Association. Thus,
            according to experts, the risk of transmission is higher when people
            are in close contact with one another for prolonged periods of time,
            particularly indoors or in other enclosed spaces. Breyer emphasized
            how the Court majority was intentionally disregarding crucial
            scientific information that would reduce the harm of Covid, just so
            that religious objectors could be exempt from complying with these
            essential laws, even if such exemptions jeopardized public health.
          </p>
          <p>
            The Free Exercise Clause is a provision of the First Amendment
            designed to protect those with any viewpoint on matters of religion
            from suffering fewer legal rights than those with different beliefs.
            It was never meant to provide exclusive special rights to avoid
            complying with generally applicable laws, particularly laws that are
            crucial to public well being.
          </p>
          <p>
            In 2021, deciding{" "}
            <em>South Bay United Pentacostal Church v. Newsom</em>, the 6 to 3
            religious right majority voted to strike down a number of
            restrictions that California had in place regarding indoor religious
            services. There were three sets of restrictions the Court dealt
            with. One restriction prohibited all indoor religious services in
            Tier 1 areas: those parts of the state with the highest infection
            rates. Another restriction limited religious gatherings in other
            parts of the state to 25% of capacity. The third restriction
            prohibited chanting and singing in all indoor services. The result
            was that the 6 vote super majority allowed indoor religious services
            in all areas of the state, regardless of infection level, at 25%
            capacity. At least the ban on chanting and singing was upheld,
            showing that the majority was willing to make a small concession to
            science as long it could be discarded overall. The 6 vote majority
            even invited houses of worship to submit evidence that they were
            being singled out for special deprivation of gathering rights which
            were not imposed on other types of groups or businesses.
          </p>
          <p>
            There were only three justices on the church/state separation side,
            Breyer, Kagan, and Sotomayor. Kagan wrote a powerful dissent, joined
            by the other two. She pointed out that California did not single out
            religious gatherings for any fewer rights. The state grouped
            together political meetings, attendance at lectures, movies, plays,
            concerts, and the hospitality industry. Religious gatherings were
            grouped with other types of gatherings,
          </p>

          <p>
            based on proximity and the length of time any danger of exposure
            lasted. In providing preferential treatment for religious
            gatherings, the majority ignored scientific evidence. California
            provided experts showing how shopping for groceries differed from
            worship services in the amount of droplets to which those engaging
            in either activity were exposed. Kagan also wrote: “This is no
            garden-variety legal error: In forcing California to ignore its
            experts’ scientific findings, the Court impairs the State’s effort
            to address a public health emergency.”
          </p>
          <p>
            IN JUNE OF THIS YEAR, THE COURT ISSUED A 6 TO 3 RULING THAT BRAZENLY
            IGNORED THE SCIENTIFIC EVIDENCE FOR HOW CLIMATE CHANGE THREATENS THE
            NATION AND THE WORLD. WITH NO ACTUAL CONSTITUTIONAL BASIS, THE
            MAJORITY DEPRIVED THE ENVIRONMENTAL PROTECTION AGENCY OF THE POWER
            GRANTED TO IT BY CONGRESS TO CURTAIL GREENHOUSE GAS EMISSIONS. THIS
            PREPOSTEROUS DECISION WAS FABRICATED FOR NO REASON OTHER THAN
            POLITICAL SUPPORT FOR THE BUSINESS INTERESTS OF POWER PLANT
            OPERATORS AND THE STATES IN WHICH THEY ARE LOCATED.
          </p>
          <p>
            In <em>West Virginia v. EPA</em>, the Court majority voted to strike
            down regulations that Congress clearly empowered the EPA to
            promulgate under the Clean Air Act. These regulations authorized
            regulating power plants that emit greenhouse gases which severely
            aggravate climate change. Just a week before, the Court majority
            obliterated almost 50 years of protecting abortion rights under the
            Constitution. A rationale used by the Court was that the term
            “abortion” appears nowhere in the Constitution. Yet, in the EPA
            case, the Court majority invented out of nowhere something called
            the “major questions” doctrine in order to have an excuse to
            invalidate the powers to regulate greenhouse gases that Congress
            conferred on the EPA. Supposedly, under this major questions
            doctrine, Congress has to be even more specific than it was if it
            wants to authorize the EPA to regulate such power plants. There is
            no basis in the Constitution for such a requirement. Under the
            Federal Supremacy Clause of the Constitution, such Congressional
            delegation of power to a specialized agency with expert knowledge in
            a given area is clearly permissible.
          </p>
          <p>
            Dissenting, Justice Kagan wrote: “Today, the Court strips the
            Environmental Protection Agency (EPA) of the power Congress gave it
            to respond to ‘the most pressing environmental challenge of our
            time.’” She concluded her dissent by saying: “The stakes here are
            high. Yet the Court today prevents congressionally authorized agency
            action to curb powerplants’ carbon dioxide emissions. The Court
            appoints itself—instead of Congress or the expert agency—the
            decision-maker on climate policy. I cannot think of many things more
            frightening.” We certainly join Justice Kagan in being frightened.
          </p>

          <p>Conclusion</p>
          <p>
            The religious right wing takeover of the Supreme Court that I have
            been literally screaming about for 42 years has finally happened.
            The only way we have any possibility of ever again being protected
            by the types of Supreme Court majorities we had in the past is from
            now on to treat every presidential election and every election for
            the U.S. Senate as a referendum on the Court. Whether pro
            church/state separation and pro science justices can be successfully
            placed on the Court when there is a vacancy will depend on who will
            be president at the time and what the composition of the Senate will
            be. Whether it takes the rest of our lives, or even a number of
            generations, we must do everything we can to ensure that at some
            point there will once again be a Supreme Court majority that follows
            reason, science, and the true meaning of the Religion Clauses of the
            First Amendment–which is to secure equal rights for believers and
            nonbelievers–and to never again allow religious objectors the
            opportunity to avoid complying with laws that were designed to
            secure the health and safety of all of us.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default MapPage

import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionSpeakers from "../components/section-speakers"
import SectionSchedule from "../components/section-schedule"
import SectionUpdates from "../components/section-updates"
import SectionInfo from "../components/section-info"

import SplashVideo from "../assets/CSIConVid-3.mp4"

const IndexPage = () => (
  <div className="page-template-home-page">
    <Layout>
      <Seo title="CSICon 2022 Homepage" />
      <div id="home" className="row landing">
        <video className="px-0" autoPlay muted loop id="homeVid">
          <source src={SplashVideo} type="video/mp4" />
        </video>
        <div className="row no-stretch">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <div id="text-outer">
              <h1>Excited about CSICon 2023?</h1>
              <h1>So are we!</h1>
              <p>
                Be sure to{" "}
                <a href="https://skepticalinquirer.org/sign-up/">
                  sign up for emails
                </a>{" "}
                to stay up to date for future announcements on CSICOn 2023,
                October 26-29 at the Flamingo Hotel
              </p>

              {/*<a
                className="btn green-button mb-3"
                href="https://skepticalinquirer.org/csicon-2022-registration/"
              >
                Register
</a>*/}
              {/*  <hr />
              <div className="audio-container mt-4 mb-3">
                <h3>Listen to the Audio Promo!</h3>
                <audio
                  style={{ width: "100%" }}
                  controls="controls"
                  preload="auto"
                  className="audio"
                >
                  <source
                    src="https://centerforinquiry.org/wp-content/uploads/sites/25/2019/05/Csicon2019-Promo.mp3"
                    type="audio/mpeg"
                  />
                </audio>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <SectionSpeakers />
      <div class="promo-row">
        <StaticImage
          src="../images/flamingo-exterior.jpg"
          objectFit="cover"
          loading="lazy"
          placeholder="blurred"
          className="flex-left-img"
          alt="Flamingo Hotel Las Vegas"
        />
        <div class="flex-right">
          <h3>Join us at CSICon 2022</h3>
          <p>
            They think the pandemic is a hoax. They think the vaccines have
            microchips and magnets. They think the aliens are here. They think
            magic potions cure diseases. They think the Earth is flat.
          </p>
          <p>
            They think that forest fires are started by Jewish space lasers.
          </p>
          <p>We're not even kidding.</p>
          <p>
            This is the infodemic, when misinformation and conspiracy theories
            are overwhelming every aspect of society, from the media and
            education to Congress and the courts. To fight this infection of
            alternative facts, we need a boost to our immune system. We need to
            bring the brightest minds in science and reason together to fight
            back against the nonsense.
          </p>
          <p>We need to do this in Vegas.</p>
          <p>
            It's finally time. Join us October 20&#8211;23 at the Flamingo
            Resort in Las Vegas for CSICon 2022, and let's get real.
          </p>
          {/*<div id="promo-buttons">
            <a
              className="btn white-button"
              href="https://skepticalinquirer.org/csicon-2022-registration/"
            >
              Register
            </a>
            </div>*/}
        </div>
      </div>
      <SectionSchedule />

      <SectionInfo />
    </Layout>
  </div>
)

export default IndexPage

import * as React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import parse from "html-react-parser"

const SPEAKERS_QUERY = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "speaker" } } }
      sort: { fields: frontmatter___order, order: ASC }
      limit: 5
    ) {
      nodes {
        html
        frontmatter {
          title
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                formats: WEBP
                height: 400
                width: 400
                quality: 10
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              )
            }
          }
        }
      }
    }
  }
`

const SectionSpeakers = props => {
  return (
    <>
      <div id="speakers" className="home-header">
        <h1 id="speakers-header">
          <strong>Speakers</strong>
        </h1>
        <hr />
      </div>
      <div className="row page-row no-stretch spkr-row">
        <div className="col-12 col-lg-8 spk_col">
          <StaticQuery
            query={SPEAKERS_QUERY}
            render={({ allMarkdownRemark: { nodes: posts } }) => {
              return posts.map(post => (
                <>
                  <div className="row home-speaker-row">
                    <GatsbyImage
                      image={
                        post.frontmatter.featuredImage.childImageSharp
                          .gatsbyImageData
                      }
                      alt={post.frontmatter.title}
                      className="speaker-img home-speaker-img"
                      loading="lazy"
                    />

                    <div className="col-12 col-md-7 home-speaker-bio">
                      <div className="speaker-bio-inner">
                        <p>
                          <strong>{post.frontmatter.title}</strong>
                        </p>
                        {parse(post.html)}
                      </div>
                    </div>
                    <GatsbyImage
                      image={
                        post.frontmatter.featuredImage.childImageSharp
                          .gatsbyImageData
                      }
                      alt={post.frontmatter.title}
                      className="speaker-img home-speaker-img"
                      loading="lazy"
                    />
                  </div>
                </>
              ))
            }}
          />
          <Link to="/speakers/" className="btn green-button" id="speakers-btn">
            See the Full Lineup
          </Link>
        </div>
      </div>
    </>
  )
}

export default SectionSpeakers
